* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Barlow", sans-serif;
}

.form {
  font-family: "Barlow", sans-serif !important;

}

.about-us {
  position: relative;
  overflow: hidden;
}
.about-us .img {

  position: absolute;
 
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: about 4s infinite;
  z-index: -1;


}
@keyframes about {

  0%{
    scale: 1;
  }
 50%{

    scale: 1.3;

  }

  
  75%{
    scale: 1.3;
  }
 100%{

    scale: 1;

  }
}
.about-us h1,
.service h1 {
  background-color: #263238;
  background-image: linear-gradient(101.61deg, #263238 20.19%, #6b8d9e 80.69%);
  background-repeat: repeat;
  background-position: center bottom;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 30px;
  position: relative;
}

.about-us h1::before,
.service h1::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-image: linear-gradient(101.61deg, #263238, #6b8d9e);
  top: 50%;
  transform: translate(-140%, -50%);
  margin: 0px 10px;
}

.about-us .circle {
  background-image: linear-gradient(101.61deg, #263238, #6b8d9e);
}

.about-us .svg-box {
  background-image: linear-gradient(#1f4f76, #052743);

  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 20px 0px;
}

.about-us h1::after,
.service h1::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-image: linear-gradient(101.61deg, #263238, #6b8d9e);
  top: 50%;
  margin: 0px 10px;
}

.what-said .linear {
  background-image: linear-gradient(to right, #1f4f76, #0d161d);

}
.consult {
  background-image: linear-gradient(rgba(0, 0, 0, 0.815), rgba(0, 0, 0, 0.87)),
    url("../images/consult-back.png");
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  /* padding: 50px 30px; */
  margin: 150px 0px 50px;
}

.why {
  min-height: 90vh;
  background-image: linear-gradient(#102739d7, #102739d7),
    url("../images/why-back-1.png");
}

.why .black {
  transition: 0.5s;
  background-color: black;
}

.why .black:hover {
  background-color: #276393;
  color: white;
}

.why .black:hover .box {
  background-color: #4b87b8;
}

.footer {
 
  position: relative;
  background: rgb(35, 36, 41 );
  overflow: hidden;
  z-index: 2;



}
.footer .img1{
  position: absolute;
   max-width: 460px;

  object-fit: contain;
  top:50px;
  left: 0px;
  transform: translateX(-28%);
  animation: img1 5s infinite;
  z-index: -1;
 
  /* z-index: 0; */
}
@keyframes img1 {

  0% {
    left: 10px;
  }
  50% {
    left:0px
  }
  75% {
    left: 0px;
  }
  100% {
    left:10px
  }
}

.footer .img2 {
  position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    mix-blend-mode: overlay;
  animation: about2 4s infinite;
  height: 80%;
  right: 0px;
  top: 30px;
  transform: translateX(100px);
  z-index: -1;
}
@keyframes about2 {

  0%{
    scale: 1;
  }
 50%{

    scale: 1.05;

  }

  
  75%{
    scale: 1.05;
  }
 100%{

    scale: 1;

  }
}
.swiper-pagination-bullet-active {
  background-color: #1f4f76 !important;
  border-radius: 0px !important;
  z-index: 1000 !important;
  width: 20px !important;
  height: 20px !important;
  border: 6px solid rgba(255, 255, 255, 0.6);
}

.swiper-pagination-bullet {
  background-color: white;
  border-radius: 0px !important;
  z-index: 1000 !important;
  width: 20px !important;
  height: 20px !important;
  border: 6px solid rgba(255, 255, 255, 0.6);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 30px) !important;
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

.index-logos {
  padding: 20px;


}

.what-container {
 }

#about .p::before {

  content :"+"


}
.what .swiper-button-next {
  /* right: 0px !important; */
  /* transform: translateX(50%); */
}
.index-image {
  filter: brightness(0.5) contrast(0.9);
  background-color: rgba(var(--ostech-secondary-rgb, 35, 36, 41), 0.6);
}
.what .slide{
  position: relative;
  background-image: url("../images/feedback.png");
  background-color: #f2f2f2;
  min-height: 80vh;

  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: center;
  align-items: center;
  width: 80vw;
  padding: 100px 0px;

background-repeat: no-repeat;
background-size: cover;

background-position: center;
}
.what .slide2::before {
  content: "\e933";
  position: absolute;
  font-size: 50px;
  top: 0px;
  color: black;
  z-index: 100;
}

.what .slide::before {
  position: absolute;
  bottom: -80px;
  left: 100px;
  content: "";
  width: 140px;
  height: 81px;
  background: var(--ostech-base, #ffc444);
  clip-path: polygon(100% 0, 0% 100%, 0 0);
}
.what .slide::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: #ffc444;
  bottom: 0px;
  left: 0px;
}
.what .swiper-button-prev,
.what .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  background:rgba(0, 0, 0, 0.44);

  padding: 10px;
  border-radius: 100%;
  font-size: 10px !important;
  color: white !important;
  width: 40px;
  height: 40px;

  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.what .swiper-button-prev:after,
.what .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 18px !important;
  font-weight: bold;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.footer .links:hover {
  color: #ffc444 !important;
  transition: 0.5s;
  margin-left: 10px;
  display: flex;
  gap: 15px !important
}

.footer .links::before {
  content: "-";
  color: #ffc444;
  transition: 0.5s;
  font-size: 30px;
  opacity: 0;
  width: 0;
  transform: translateY(-3px);
}

.footer .links:hover::before {
  opacity: 1;
  width: 3px;
}

.what-said .a {
  color: white;
}

.what-said .a:hover {
  color: #ffc444;
  transition: 0.5s;
}

.service .a,
.consult .a {
  background-color: #1f4f76;
  transition: 0.5s;
}

.service .a:hover,
.consult .a:hover {
  background-color: #1f4f76c9;
}

.drawer {
  background-color: rgba(0, 0, 0, 0.527);
}

.drawer .content {
  background-image: linear-gradient(#1f4f76, #0c2233);
}

.index-layer {
  background-color: rgba(0, 0, 0, 0.753);
}

.loading {
  background-image: linear-gradient(#1f4f76, #0c2233);
}

.top-shared {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  object-fit: cover;
  background-attachment: scroll;
  background-position: center;
  color: white;

  background-image: linear-gradient(rgba(0, 0, 0, 0.426), rgba(0, 0, 0, 0.503)),
    url("../images/topshared.jpg");
}

.contact-container {
  background-color: white;
  box-shadow: 3px 3px 20px #f2f2f2;
}

.contact-icon:hover {
  background-color: white;
  color: black;
}

.contact-icon {
  transition: 0.5s;
  font-size: 20px;

  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
  color: white;
  border-radius: 100%;
}

.contact-p::before {
  content: "";
  width: 15px;
  border-radius: 2px;
  border: 2px solid #276393;
}

.contact-p {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.service-abs {
  background-color: #232429;
  width: 100%;
}

.service-box {
  margin: auto;
  margin-top: 20px;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  color: #1f4f76;
  padding: 20px;
  font-size: 45px;
  border: 10px solid black;
}

.serve .icon {
  padding: 40px 25px;
  font-size: 35px;
  background-color: #276393;
  transition: 0.5s;
}

.serve .icon:hover {
  background-color: #232429;
  color: #276393;
}

.serve .item {
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 3px 3px 6px #f2f2f2;
}

.serve .h1 {
  font-size: 30px;
  font-weight: 500;
}

.serve .p {
  color: #686a6f;
  margin-top: 10px;
}

.serve .feilds {
  background-color: #276393 !important;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.feilds-item h1::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #232429;
  bottom: 0px;
  left: 30px;
  transform: translateY(50%) rotate(45deg);
}

.feilds-item h1 {
  color: white;
  padding: 20px;
  position: relative;

  background-color: #232429;
}

.serve .feild {
  padding: 15px;
  background-color: white;
  overflow: hidden;

  display: flex;
  align-items: center;

  justify-content: space-between;
  color: #686a6f;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.serve .feild .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  transition: 1s;

  background-color: #232429;
  left: -120%;
  font-weight: 500;
  padding: 15px;

  color: white;
}

.serve .feild:hover .layer {
  left: 0px;
}

.serve .contact-link,
.index-slide .contact-link {

  background-color: #276393;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;

  width: 140px;
  text-align: center;
  position: relative;
  transition: 0.4s;
  overflow: hidden;
}

.serve .contact-link:hover,
.index-slide .contact-link:hover {
  color: black;
  background-color: transparent;
  z-index: 0;
}

.serve .contact-link::after,
.index-slide .contact-link::after {

  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  background-color: white;
  right: -100%;
  top: 0px;
  z-index: -1;

}

.serve .contact-link::before,
.index-slide .contact-link::before {


  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  background-color: white;
  left: -100%;
  bottom: 0px;
  z-index: -1;
  transition: 0.4s;

}

.serve .contact-link:hover::before,
.index-slide .contact-link:hover::before {

  left: 0px;
}

.serve .contact-link:hover::after,
.index-slide .contact-link:hover::after {
  transition: 0.4s;

  right: 0px !important;
}


.service .layer-parent img {
  overflow: hidden;
  transition: 0.5s;

}

.acc::before {

  display: none !important;
}

@media only screen and (max-width: 700px) {
  .index-slide .h1 {
    font-size: 35px !important;
    padding: 20px;
  }
}

.service-layer {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left: 0;
  content: "";
  width: 100%;
  min-height: 63%;
  padding: 20px 10px;
  background: rgba(var(--ostech-secondary-rgb, 35, 36, 41), 0.82);
  clip-path: polygon(0 0, 100% 35%, 100% 100%, 0% 100%);
}
.service-layer img {

height: 340px;
}
.topVec {


  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: translateX(20px);
}

.topVec img {

  width: 150px;
  


}


.topVec .img2 {
  display: none;
}
.img-item:hover {
  color: white ;
}
.img-item:hover .img1 {
  display: none;
}
.img-item:hover  .img2{

  display: block;

}

.serve-p {
  color: var(--ostech-text-gray, #999B9F);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 187.5%;
  margin-bottom: 0px;
  padding-bottom: 0;
}
.nav-link {
  font-weight: 550 !important;
}
.nav-link:hover {

  color: #ffc444 !important;
  transition: 0.4s;

}
.img-item{
  color:#276393;
  height: 360px;


}
.img-item .standard{
  height: 360px;
  width: 100%;
  object-fit: cover;
}


.star {

  animation: star 3s infinite;
}
@keyframes star {

  0%{

    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }


}

.slide span {

  font-size: 22px;
  font-weight: 500;
}

.box-2{
  
  width: 65vw;

  position: absolute;
  top: 10%;

  height: 75vh;
  border: 0.5px solid #686a6f;
  animation: box1 2s infinite;

}
.box-1 {

  width: 68vw;
  top: 10%;

  position: absolute;

  height: 70vh;
  border: 0.5px solid #ffc444;
  animation: box1 3s infinite;
}

@keyframes box1 {


  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1.4);
  }

  }

.left-animate {
  display: flex;
  width: calc(100% * 4); /* Adjust this based on the number of slides */
  height: 100%;
  white-space: nowrap;

  animation: slide-left 30s linear infinite;
}

.linear:hover .left-animate {
  animation-play-state: paused;
}


@keyframes slide-left {
  0% {
    transform: translateX(0) ;
  }
  100% {
    transform: translateX(-100%) ;
  }
}

.feedback {

  background-image: url("../images/feedback.png");
}